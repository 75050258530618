
import React, { useEffect, useState } from "react";
import "fao-design-system/fao-theme.min.css";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const FooterApplication = ({ lang }) => {

  return (
    <div>
      <footer id="footer">    
         {/* <div className="container-fluid"> */}
          <div className="row">
            <div className="footer-elements">
              <div className="col-8 links-footer">
              <a
                  className={"bottom-links-text"}
                  target="_blank"
                  href={`http://www.fao.org/employment/home/${lang}/`} rel="noreferrer"
                >
                  <FormattedMessage id="Footer.footerColumn21" />
                  &nbsp; <span style={{ color: "lightgrey" }}> | </span> &nbsp;
                </a>
                <a
                  className={"bottom-links-text"}
                  target="_blank"
                  href={`http://www.fao.org/contact-us/${lang}/`} rel="noreferrer"
                >
                  <FormattedMessage id="Footer.footerColumn11" />
                  &nbsp; <span style={{ color: "lightgrey" }}> | </span> &nbsp;
                </a>
                <a
                  className={"bottom-links-text"}
                  target="_blank"
                  href={`http://www.fao.org/contact-us/terms/${lang}/`} rel="noreferrer"
                >
                  <FormattedMessage id="Footer.footerColumn12" />
                  &nbsp; <span style={{ color: "lightgrey" }}> | </span> &nbsp;{" "}
                </a>
                <a
                  className={"bottom-links-text"}
                  target="_blank"
                  href={`http://fao.org/contact-us/data-protection-and-privacy/${lang}/`} rel="noreferrer"
                >
                  <FormattedMessage id="Footer.footerColumn15" />
                  &nbsp; <span style={{ color: "lightgrey" }}> | </span> &nbsp;
                </a>
                <a
                  className={"bottom-links-text"}
                  target="_blank"
                  href={`http://www.fao.org/contact-us/scam-alert/${lang}/`} rel="noreferrer"
                >
                  <FormattedMessage id="Footer.footerColumn13" />
                  &nbsp; <span style={{ color: "lightgrey" }}> | </span> &nbsp;{" "}
                </a>
                <a
                  className={"bottom-links-text"}
                  target="_blank"
                  href={`https://www.fao.org/contact-us/report-misconduct/${lang}/`} rel="noreferrer"
                >
                  <FormattedMessage id="Footer.footerColumn14" />
                </a>
              </div>
              <div className="col-1 copyright">
                <p>© FAO, {moment(new Date()).format("YYYY")}</p>
              </div>
            </div>
          </div>
        {/* </div> */}
      </footer>
    </div>
  );
};

export default FooterApplication;
