export class Costants {
  
  static optionSort = ["relevance","date_of_text"];

  static topicJson = [
    { id: "WE", name: "women" },
    { id: "TS", name: "measures" },
    { id: "GNA", name: "natural_resurces" },
    { id: "GNU", name: "nutrition" },
    { id: "GS", name: "social_protection" },
    { id: "GF", name: "financial_services" }
  ];

  static typeJson = [
    { id: "P", name: "policy" },
    { id: "L", name: "legislation" },
    { id: "R", name: "regulation" },
    { id: "M", name: "miscellaneous" },
    { id: "C", name: "constitution" }
  ];

  static psJson = [
    { id: "Agricultural and rural development", name: "agricolture" },
    { id: "Livestock", name: "livestock" },
    { id: "Energy", name: "energy" },
    { id: "Environment", name: "enviroment" },
    { id: "Food and nutrition", name: "food_nutrition" },
    { id: "Fisheries", name: "fisheries" },
    { id: "Forestry", name: "forestry" },
    { id: "Land and soil", name: "land_soil" },
    { id: "Mineral resources", name: "mineral_resources" },
    { id: "Cultivated plants", name: "cultivated_plants" },
    { id: "Sea", name: "sea" },
    { id: "Water", name: "water" },
    { id: "Wild species and ecosystems", name: "spicies_eco" },
    { id: "National development", name: "national_development" },
    { id: "Gender", name: "gender" },
    { id: "Health", name: "health" },
    { id: "Indigenous peoples", name: "indigenous_peoples" },
    { id: "Climate change", name: "climate_change" },
    { id: "Disaster risk management", name: "disaster_risk_management" },
    { id: "Social protection", name: "social_protection" },
    { id: "Employment", name: "employment" },
    { id: "Trade", name: "trade" },
    { id: "Youth", name: "youth" },
    { id: "Private sector / entrepreneurship", name: "priv_sector_entrepreneurship" },
    { id: "Governance", name: "general" },
    
  ];

  static mapQuery = [
    "record",
    "title",
    "topic",
    "mainareas",
    "country",
    "geographicalareas",
    "repealed",
    "typeoftext"
  ];

  static facetsMap = [
    {field: "countries", name: "By Country/Territory", priority: 7},
    {field: "mainareas", name: "By Primary Subject", priority: 3},
    {field: "subjectselections", name: "By Domain", priority: 2},
    {field: "keywords", name: "By Keywords", priority: 4},
    {field: "geographicalareas", name: "By Geographical Area", priority: 5},
    {field: "typeoftexts", name: "By Type Of Text", priority: 9},
    {field: "documentlanguages", name: "By Languages", priority: 11},
    {field: "territorialsubdivisions", name: "By Territorial Subdivision", priority: 8},
    {field: "organization", name: "By Regional Organization", priority: 6},
    {field: "facetyear", name: "By Year", priority: 10},
    {field: "topic", name: "By Topic", priority: 1},
    {field: "typeoftext", name: "By Type", priority: 12}
  ];

  static yearFromMap = ["yearfrom", "originalyearfrom"];
  static yearToMap = ["yearto", "originalyearto"];

  static tableInfoMap = [
    {field: "typeOfText", name: "Type of text"},
    {field: "relatedWebSite", name: "Related web site"},
    {field: "faolexid", name: "Lex."},
    {field: "documentLanguage", name: "Document language"},
    {field: "dateOfText", name: "Date of text"},
    {field: "dateOfOriginalText", name: "Date of original text"},
    {field: "dateOfConsolidation", name: "Last amended date"},
    {field: "keyword", name: "Keywords"},
    {field: "subjectSelection", name: "Domains"},
    {field: "mainArea", name: "Primary Subjects"},
    {field: "dateOfModification", name: "Date of modification"}
  ];

  static tableAllviewMap = [
    "topic",
    "type",
    "titleOfText",
    "dateOfText",
    "faolexId",
    "typeOfText"
  ]
}
