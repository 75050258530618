import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./DocumentsDetail.css";
import Pagination from "../../helper/Paginator/Pagination";
import Config from "../../config";
import { useLocation } from "react-router-dom";

function DocumentsDetail( { lang } ) {
  const { country, subsectionTitle } = useParams();
  const [documents, setDocuments] = useState([]);
  const [documents2, setDocuments2] = useState([]);
  const [nResult, setNResult] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = 6;

  // var language = window.location.pathname.substring(
  //   window.location.pathname.length - 2
  // );

  var countryId = window.location.pathname.split("/")[4];

  // let dateLang = language;
  // if (
  //   dateLang !== "fr" &&
  //   dateLang !== "es" &&
  //   dateLang !== "ru" &&
  //   dateLang !== "ar" &&
  //   dateLang !== "zh"
  // ) {
  //   dateLang = "en";
  // }

  var language = lang;


  useEffect(() => {
    fetch(Config.apis.countryprofiles)
      .then((response) => response.json())
      .then((data) => {
        var profile = data.profile.filter(
          (x) => x.country_ISO3_Code === countryId
        );
        var section = profile[0].section.filter(
          (x) => x["SectionTitle_" + language] === subsectionTitle.replace("%20", " ")
        );
        setNResult(section[0].document.length);
        setDocuments(section);
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        setDocuments2(section[0].document.slice(firstPageIndex, lastPageIndex));
      });
  }, [PageSize, countryId, currentPage, subsectionTitle, documents, language]);

  return (
    <div id="documents-detail">
      <h2 className="page-title mb-3">
        <span
          className={`flag flag-${countryId.toString().toLowerCase()}`}
        ></span>
        {country}
      </h2>
      {documents.map((desc) => (
        <div key={desc["SectionTitle_" + language]}>
          <p className="my-4">{`${nResult} results found`}</p>
          <h4 className="title-highlight">{desc["SectionTitle_" + language]}</h4>
          <h6 className="description">{desc["SectionDescription_" + language]}</h6>
          
          {documents2 !== null
            ? documents2.map((doc) => (
                <div key={doc.faolex_id}>
                  <div className="table-results-a mt-3">
                    <Link to={`document/${doc.faolex_id}`}>
                      {doc.Title_of_Text ? doc.Title_of_Text : ""}
                    </Link>
                    {doc.Date_of_Text}
                  </div>
                  <div className="table-results-a">
                    {doc.topic !== null
                      ? doc.topic.map((topic) => (
                          <div key={doc.faolex_id}>
                              {topic}                            
                          </div>
                        ))
                      : null}
                    {/* <p>{doc.topic}</p> */}
                  </div>
                </div>
              ))
            : null}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={documents[0].document.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ))}
    </div>
  );
}

export default DocumentsDetail;
