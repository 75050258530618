import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function ButtonNav({ icon, text, showText, selected, link, lang, type }) {

  const [buttonType, setButtonType] = useState('');

  useEffect(() => {
    setButtonType(type);
  }, [type]);

  return (
    buttonType !== 'home' ?
    <Link to={link}>
      <button className={selected ? "button-nav" : "button-selected"}>
        <i id="asideToggleIcon" className={icon}></i>
        {showText ? (
          <span className="text-nav">
            <FormattedMessage id={text} />
          </span>
        ) : null}
      </button>
    </Link> :
    <Link to={link + lang}>
    <button className={selected ? "button-nav" : "button-selected"}>
      <i id="asideToggleIcon" className={icon}></i>
      {showText ? (
        <span className="text-nav">
          <FormattedMessage id={text} />
        </span>
      ) : null}
    </button>
  </Link>
  );
}

export default ButtonNav;
