import { Link, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import configPws from "../config";
import { QueryAdvSearch } from "./QueryAdvSearch";
import { QueryCountryDocumentsSearch } from "./QueryCountryDocumentsSearch";
import CircleGreen from "../images/circle-solid-green.svg";
import Config from "../config";
import ViewSpinner from "../shared/Spinner";
import { DownloadQueryTablePDF } from "./DownloadPDF";
import { DownloadXLS } from "./DownloadXLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

let documentsss = [];

function TranslationNumber(language) {
  if (language) {
    switch (language) {
      case "fr":
        return 1;
      case "es":
        return 2;
      case "ar":
        return 3;
      case "ru":
        return 4;
      case "zh":
        return 5;
      default:
        return 0;
    }

  } else {
    return 0;
  }
}

function AllDocumentsView( { lang } ) {
  const { countryId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [country, setCountry] = useState("");
  const [countryPDF, setCountryPDF] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    setIsLoading(false);

    fetch(configPws.apis.gcs, {
      method: "POST",
      body: QueryCountryDocumentsSearch(countryId, page),
    })
      .then((response) => response.json())
      .then((data) => {
        let id = "";
        let typeOfText = "";
        let title = "";
        let date = "";
        let topicsArray = [];
        let titlePdf = "";
        for (let el of data.results) {
          titlePdf = el.title;
          topicsArray = [];
          for (let dc of el.metadata.fields) {
            switch (dc.name) {
              case "faolexId":
                id = dc.textValues.values[0];
                break;
              case "typeOfText":
                typeOfText = dc.textValues.values[0];
                break;
              case "titleOfText":
                title = dc.textValues.values[0];
                break;
              case "dateOfText":
                date = dc.textValues.values[0];
                break;
              case "topic":
                topicsArray.push(dc.textValues.values);
                break;
              case "country":
                if (dc.textValues.values.length > 1) {
                  setCountry((c) => c);
                } else {
                  setCountry(dc.textValues.values[0].split("#")[TranslationNumber(lang)]);
                  if(lang=='zh' || lang== 'ar')
                    setCountryPDF(dc.textValues.values[0].split("#")[0])
                  else
                    setCountryPDF(country)
                }

                break;

              default:
            }
          }

          let documentview = {
            id: id,
            typeOfText: typeOfText,
            title: title,
            date: date,
            topicresources: false,
            topicempowerment: false,
            topicnutrition: false,
            topicsocial: false,
            topicfinancial: false,
            topictemporary: false,
            pdf: titlePdf,
          };

          for (let tpl of topicsArray[0] || topicsArray) {
            if (tpl === "Gender and natural resources") {
              documentview.topicresources = true;
            }
            if (tpl === "Women's empowerment") {
              documentview.topicempowerment = true;
            }
            if (tpl === "Gender and nutrition") {
              documentview.topicnutrition = true;
            }
            if (tpl === "Gender and social protection") {
              documentview.topicsocial = true;
            }
            if (tpl === "Gender and financial services") {
              documentview.topicfinancial = true;
            }
            if (tpl === "Temporary special measures") {
              documentview.topictemporary = true;
            }
          }

          documentsss.push(documentview);
        }

        if (!data.hasMoreResults) {
          setDocuments(documentsss);
        } else {
          setPage(page + 100);
        }
      })
      .then(() => setIsLoading(true));
  }, [countryId, page, lang]);

  const DownloadPdf = () => {
    let rowInit = [
      [
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.id'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.type'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.title'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.country.dateoftext.pdf'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.gender'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.women'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.nutrition'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.social'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.services'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: intl.formatMessage({id: 'Country_profiles.pdf_row.measures'}),
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
      ],
    ];

    documents.forEach((document) => {
      rowInit.push([
        {
          text: document.id,
          link: `${Config.urls.link_document}${document.id}`,
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "blue",
        },
        {
          text: document.typeOfText.split("#")[TranslationNumber((lang=="zh" || lang == "ar")?"en":lang)],
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
        },
        {
          text: document.title,
          link: `${Config.urls.link_pdf}${document.pdf}`,
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "blue",
        },
        {
          text: document.date,
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
        },
        {
          text: document.topicresources ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicempowerment ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicnutrition ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicsocial ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicfinancial ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topictemporary ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
      ]);
    });

    DownloadQueryTablePDF(rowInit, countryPDF,lang);
  };

  const downloadXls = () => {
    let filesPdf = [];
    let formatXlsJson = [
      {
        id: intl.formatMessage({id: 'Country_profiles.id'}),
        typeOfText: intl.formatMessage({id: 'Country_profiles.type'}),
        title: intl.formatMessage({id: 'Country_profiles.title'}),
        date: intl.formatMessage({id: 'Country_profiles.country.dateoftext'}),
        topicresources: intl.formatMessage({id: 'Country_profiles.gender'}),
        topicempowerment: intl.formatMessage({id: 'Country_profiles.women'}),
        topicnutrition: intl.formatMessage({id: 'Country_profiles.nutrition'}),
        topicsocial: intl.formatMessage({id: 'Country_profiles.social'}),
        topicfinancial: intl.formatMessage({id: 'Country_profiles.services'}),
        topictemporary: intl.formatMessage({id: 'Country_profiles.measures'}),
      },
    ];
    documents.forEach((data) => {
      filesPdf.push(data.pdf);
    });

    documents.forEach((data) => {
      formatXlsJson.push({
        id: data.id,
        typeOfText: data.typeOfText.split("#")[TranslationNumber(lang)],
        title: data.title,
        date: data.date,
        topicresources: data.topicresources ? "•" : "",
        topicempowerment: data.topicempowerment ? "•" : "",
        topicnutrition: data.topicnutrition ? "•" : "",
        topicsocial: data.topicsocial ? "•" : "",
        topicfinancial: data.topicfinancial ? "•" : "",
        topictemporary: data.topictemporary ? "•" : "",
      });
    });

    DownloadXLS(formatXlsJson, filesPdf, "Gender_lex_" + country);
  };

  return isLoading ? (
    <div>
      <div id="all-documents">
        <div className="header-wrap">
          <div className="country">
            <div className="img-country">
              <img
                className="country-page-flag"
                width="50px"
                alt=""
                src={`https://www.fao.org/fileadmin/assets/countries/flags/${
                  countryId && countryId.toString().toLowerCase()
                }.gif`}
              ></img>
            </div>
            <div className="name-country">
                <h3>{country}</h3>
              
            </div>
          </div>
          <div className="results-found">
            <h5>{documents ? documents.length : 0} <FormattedMessage id="Country_profiles.results" /></h5>
          </div>
          <div className="buttons-download">
            <button
              type="submit"
              className="btn btn-primary me-4"
              onClick={() => DownloadPdf()}
            >
              <FormattedMessage id="Country_profiles.pdf_download" />
              <FontAwesomeIcon
                icon={faFilePdf}
                color="red"
                data-toggle="tooltip"
                data-placement="left"
                style={{ marginLeft: "5px" }}
              />
            </button>
            <button
              type="submit"
              className="btn btn-primary me-4"
              onClick={() => downloadXls()}
            >
              <FormattedMessage id="Country_profiles.xls_download" />
              <FontAwesomeIcon
                icon={faFileExcel}
                color="#90ee90"
                data-toggle="tooltip"
                data-placement="left"
                style={{ marginLeft: "5px" }}
              />
            </button>
          </div>
        </div>

        <table
          id="table-results"
          className="table"
          style={{ verticalAlign: "middle", fontSize: "13px" }}
        >
          <thead style={{ verticalAlign: "middle" }}>
            <tr>
              <th scope="col"><FormattedMessage id="Country_profiles.id" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.type" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.title" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.country.dateoftext" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.gender" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.women" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.nutrition" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.social" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.services" /></th>
              <th scope="col"><FormattedMessage id="Country_profiles.measures" /></th>
            </tr>
          </thead>
          {documents
            ? documents.map((document) => (
                <tbody key={document.id}>
                  <tr>
                    <td style={{ width: "140px" }}><a href={"/search/document/"+document.id}>{document.id}</a></td>
                    <td>{document.typeOfText.split("#")[TranslationNumber(lang)]}</td>
                    <td>{document.title}</td>
                    <td style={{ width: "100px" }}>{document.date}</td>
                    <td>
                      {document.topicresources ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicempowerment ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicnutrition ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicsocial ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicfinancial ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topictemporary ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              ))
            : null}
        </table>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ViewSpinner />
    </div>
  );
}

export default AllDocumentsView;
