/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
// import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

const HeadLanguage = ({ lang }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const history = useHistory();
  const [selected, setIsSelected] = useState(lang)

  useEffect(() => {
    setIsSelected(lang);
  }, [lang]);

  const splitUrl = (lang) => {
    let newURL;
    // if (location.pathname === "/" || location.pathname.includes(".html")) {
    //   newURL = location.pathname.split("/").slice(0, -1).join("/") + "/" + lang;
    // } else {
    //   lang = lang.replace(".html", "");
    //   newURL = location.pathname.split("/").slice(0, -1).join("/") + "/" + lang;
    // }
    let temp = location.pathname.split("/")
    temp[1] = lang
    newURL = temp.join("/")
    console.log(newURL)
    return newURL + location.search;
  };

  // function isSelected(languageSelected) {
  //   if (languageSelected === lang) {
  //     return "selected";
  //   } else return "";
  // }

  return (
    <div className="align-self-center" style={{}}>
        <div className="d-flex">
          <select
            name="selectLang"
            onChange={(event) => {
              navigate(splitUrl(event.target.value));
              window.location.reload();
            }}
            className="select-lang"
            id="selectLang"
            defaultValue={selected}
          >
            <option value="en">
              ENGLISH
            </option>
            <option value="ar">
              العربية
            </option>
            <option value="zh">
              ﻿中文
            </option>
            <option value="ru">
              Русский
            </option>
            <option value="fr">
              FRANÇAIS
            </option>
            <option value="es">
              ESPAÑOL
            </option>
          </select>
         
        </div>
    </div>
  );
};

export default HeadLanguage;
