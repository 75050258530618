const dev = {
  apis: {
    countryprofiles: "https://faolex.fao.org/docs/json/genderlexCP.json",
    languages: "/fileadmin/templates/gcs/js/languages.min.js",
    relatedwebsites: "https://faolex.fao.org/docs/json/GenderlexWeb.json",
    territorialsubdivision: "https://faolex.fao.org/docs/json/genderlex_subdivision.json",
    gcs: "https://fao-faolex-dev.appspot.com/api/query",
    geo: "/json/geo.json"
  },
  urls: {
    faolex: {
      fao_home: "https://www.fao.org/home/en/",
      country_profiles: "https://www.fao.org/faolex/country-profiles/general-profile/en/?iso3=",
      details: "https://www.fao.org/faolex/results/details/en/c/",
      image_fao:"https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-",
      image_home: "https://storage.googleapis.com/fao-amr-lex-static/images/house-fill.svg"
    },
    flags_organization: "https://faolex.fao.org/docs/pdf/imgs/",
    flags_country: "https://www.fao.org/images/corporatelibraries/flags/",
    link_document: "http://fao-faolex-genderlex-r2475i4kfq-ew.a.run.app/search/document/",
    link_pdf: "http://faolex.fao.org/docs/pdf/"
  }
};

const prod = {
  apis: {},
  urls: {},
  filterConfig: [{}],
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const configPws = {
  // Add common config values here
  // MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
  PwsSearchApplicationId: "searchapplications/1be285f8874b8c6b09629075d87b057f",
};

export default configPws;