import { useEffect, useState } from "react";
import { Link, useRouteMatch, useParams, useLocation } from "react-router-dom";
import Config from "../config";

function CountrySel({ fields, lang }) {
  const [iso, setIso] = useState("");
  const [terrSub, setTerrSub] = useState("");
  const [countrySel, setCountrySel] = useState("");
  const [language, setLanguage] = useState(lang);
  const [languageNumber, setLanguageNumber] = useState(0);

  useEffect(() => {
    switch (lang) {
      case "fr":
        setLanguageNumber(1);
        break;
      case "es":
        setLanguageNumber(2);
        break;
      case "ar":
        setLanguageNumber(3);
        break;
      case "ru":
        setLanguageNumber(4);
        break;
      case "zh":
        setLanguageNumber(5);
        break;
      default:
        setLanguageNumber(0);
    }
  }, [languageNumber,lang]);

  useEffect(() => {
    var geoIso = "";
    var geoName = "";
    var countryIso = "" || [];
    var countryName = "" || [];
    var terrSub = "";

    var mapCountry = {};

    fields.forEach((field) => {
      if (field.name === "organizationCode") {
        if (field.textValues.values.length <= 1) {
          geoIso = field.textValues.values[0];
        } else {
          var geoIsoArray = [];
          field.textValues.values.forEach((g) => {
            geoIsoArray.push(g);
          });
          geoIso = geoIsoArray;
        }
      }
      if (field.name === "organization" || field.name === "organizationEn") {
        if (field.textValues.values.length <= 1) {
          geoName = field.textValues.values[0].split("#")[languageNumber];
        } else {
          var geoNameArray = [];
          field.textValues.values.forEach((g) => {
            geoNameArray.push(g ? g.split("#")[languageNumber] : "000");
          });
          geoName = geoNameArray;
        }
      }
      if (field.name === "countryISO3") {
        if (field.textValues.values.length <= 1) {
          countryIso = field.textValues.values[0];
        } else {
          var multiCountryIso = [];
          field.textValues.values.forEach((c) => {
            multiCountryIso.push(c ? c : "000");
          });
          countryIso = multiCountryIso;
        }
      }
      if (field.name === "country") {
        if (field.textValues.values.length <= 1) {
          countryName = field.textValues.values[0];
        } else {
          var multiCountry = [];
          field.textValues.values.forEach((c) => {
            multiCountry.push(c ? c : "000");
          });
          countryName = multiCountry;
        }
      }
      if (field.name === "territorialSubdivision") {
        terrSub = field.textValues.values[0];
      }
    });

    var country = [];
    var geo = [];

    if (Array.isArray(countryName) && Array.isArray(countryIso)) {
      for (var i = 0; i < countryIso.length; i++) {
        country.push({
          countryIso: countryIso[i],
          countryName: countryName[i],
        });
      }
    }
    if (Array.isArray(geoName)) {
      for (var j = 0; j < geoName.length; j++) {
        geo.push({
          geoIso: geoIso[j],
          geoName: geoName[j],
        });
      }
    }
    if (country.length !== 0) {
      mapCountry = {
        geoIso: geoIso,
        geoName: geoName,
        country: country,
        terrSub: terrSub,
      };
    } else {
      mapCountry = {
        geoIso: geoIso,
        geoName: geoName,
        countryIso: countryIso,
        countryName: countryName,
        terrSub: terrSub,
      };
    }

    setCountrySel(mapCountry);
  }, [fields, lang]);

  return (
    <div id="country">
      {countrySel.geoName ? (
        <div className="section-geo">
          <img
            alt=""
            src={`${Config.urls.flags_organization}${
              countrySel.geoIso.toLowerCase()
            }.gif`}
          ></img>
          <h3>{countrySel.geoName}</h3>
        </div>
      ) : null}

      {countrySel.country ? (
        countrySel.country.map((c) => (
          <div className="country-wrap" key={c.countryIso}>
            <div className="section-flag">
              <img
                alt=""
                src={`${Config.urls.flags_country}${
                  c.countryIso && c.countryIso.toString().toLowerCase()
                }.svg`}
              ></img>
            </div>
            <div className="section-name">
              <Link
                to={`/country/profiles/${countrySel.countryIso}`}
                target="_blank"
              >
              {console.log("country test: ", c)}
                <h3>{c.countryName.split("#")[languageNumber]}</h3>
              </Link>
              <h3 style={{ marginLeft: "10px" }}>
                {countrySel.terrSub && countrySel.terrSub !== "National level"
                  ? `(${countrySel.terrSub.split("#")[languageNumber]})`
                  : null}
              </h3>
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className="country-wrap">
            <div className="section-flag">
              <img
                alt=""
                src={`${Config.urls.flags_country}${
                  countrySel.countryIso &&
                  countrySel.countryIso.toString().toLowerCase()
                }.svg`}
              ></img>
            </div>
            <div className="section-name">
              <Link
                to={`/${language}/country/profiles/${countrySel.countryIso}`}
                target="_blank"
              >
               {console.log("country test: ", countrySel)}
                {countrySel.countryName!= null && countrySel.countryName.indexOf('#') > -1 ? (
                                              <h3>{countrySel.countryName.split("#")[languageNumber]}</h3>
                                            ) : <h3>{countrySel.countryName}</h3>}
              </Link>

              {countrySel.terrSub != null && countrySel.terrSub.indexOf('#') > -1 ? (
                !countrySel.terrSub.includes("National level") ? (
                  <h3 style={{ marginLeft: "10px" }}>({countrySel.terrSub.split("#")[languageNumber]})</h3>
                ) : null
              ) : (
                <h3 style={{ marginLeft: "10px" }}>({countrySel.terrSub})</h3>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CountrySel;
