import { FormattedMessage } from "react-intl";
import "./About.css";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";

function About() {
  const intl = useIntl();
  var numbers9 = [...Array(9).keys()];
  // var numbers3 = [...Array(3).keys()];
  // var numbers2 = [...Array(2).keys()];

  const [main, setMain] = useState("");
  // const [importance, setImportance] = useState("");
  // const [faoConvention, setFaoConvention] = useState("");
  // const [faoWork, setFaoWork] = useState("");

  useEffect(() => {
    
    setMain(intl.formatMessage({ id: "About.texts.text" }).split("#"));
    // setImportance(
    //   intl.formatMessage({ id: "About.importance.text" }).split("#")
    // );
    // setFaoConvention(
    //   intl.formatMessage({ id: "About.fao_convention.text" }).split("#")
    // );
    // setFaoWork(intl.formatMessage({ id: "About.fao_work.text" }).split("#"));
  
}, [intl])

  return (
    <div id="about">
      {numbers9.map((el) => (
        <p key={el}>{main[el]}</p>
      ))}
      {/* <h5>
        <FormattedMessage id="About.importance.title" />
      </h5>
      {numbers3.map((el) => (
        <p key={el}>{importance[el]}</p>
      ))}
      <h5>
        <FormattedMessage id="About.fao_work.title" />
      </h5>
      <p>
        <FormattedMessage id="About.fao_work.text" />
      </p>
      <h5>
        <FormattedMessage id="About.fao_convention.title" />
      </h5>
      {numbers3.map((el) => (
        <p key={el}>{faoConvention[el]}</p>
      ))}
      <h5>
        <FormattedMessage id="About.role.title" />
      </h5>
      {numbers2.map((el) => (
        <p key={el}>{faoWork[el]}</p>
      ))} */}
    </div>
  );
}

export default About;
