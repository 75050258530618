import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Natural from "../../images/_home/02.png";
import Women from "../../images/_home/03.jpg";
import Nutrition from "../../images/_home/04.jpg";
import Social from "../../images/_home/05.png";
import Services from "../../images/_home/06.jpg";
import Measures from "../../images/_home/07.jpg";
import Config from "../../config";
import { Link } from "react-router-dom";
import About from "../About/About";
import { Tooltip } from "primereact/tooltip";
import "./Home.css";
import ViewSpinner from "../../shared/Spinner";
import { Button } from "primereact/button";

function Home() {
  const [relatedLinks, setRelatedLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const [accordionAbout, setAccordionAbout] = useState({
    accordion: "accordion-collapse collapse",
    button: "accordion-button collapsed",
  });

  var dateLang = window.location.pathname.substring(
    window.location.pathname.length - 2
  );
  if (
    dateLang !== "fr" &&
    dateLang !== "es" &&
    dateLang !== "ru" &&
    dateLang !== "ar" &&
    dateLang !== "zh"
  ) {
    dateLang = "en";
  }
  var lang = dateLang ? dateLang : "en";

  useEffect(() => {
    fetch(Config.apis.relatedwebsites)
      .then((data) => data.json())
      .then((data) => {
        setIsLoading(false);
        setRelatedLinks(data.related_websites);
        console.log(data.related_websites)
      })
      .then(() => setTimeout(setIsLoading(true), 1000));
  }, []);

  const cards = [
    {
      name: "women",
      image: Women,
      text: "women_description",
    },
    {
      name: "measures",
      image: Measures,
      text: "measures_description",
    },
    {
      name: "natural",
      image: Natural,
      text: "natural_description",
    },
    {
      name: "nutrition",
      image: Nutrition,
      text: "nutrition_description",
    },
    {
      name: "social",
      image: Social,
      text: "social_description",
    },
    {
      name: "services",
      image: Services,
      text: "services_description",
    },
  ];

  const changeAccordionAbout = () => {
    if (accordionAbout.accordion === "accordion-collapse collapse") {
      setAccordionAbout({
        accordion: "accordion-collapse collapse show",
        button: "accordion-button",
      });
    } else {
      setAccordionAbout({
        accordion: "accordion-collapse collapse",
        button: "accordion-button collapsed",
      });
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="main">
          <div className="home">
            <div>
              <div className="card-title text-title-small">
                <p style={{ color: "white" }}>
                  {/* <Button label="Gender-Lex is…" className="p-button-home" tooltip={intl.formatMessage({ id: 'Home.primary_text' })} tooltipOptions={{ className: 'tooltip-home'}}/> */}
                </p>
              </div>
              <div className="button-wrap">
                <div className="">
                  <Link to="country" className="text-button">
                    <button className="button-main">
                      <i
                        id="asideToggleIcon"
                        className="bi bi-globe inside-icon"
                      ></i>
                      <FormattedMessage id="Country_profiles.country.title" />
                    </button>
                  </Link>
                </div>

                <Link to="search" className="text-button">
                  <button className="button-main">
                    <i
                      id="asideToggleIcon"
                      className="bi bi-search inside-icon"
                    ></i>
                    <FormattedMessage id="Navigation.advanced" />
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="row mt-5 mx-5">
            <div className="col-8">
              <div className="text-home">
                <p>
                  <FormattedMessage id="Home.primary_text" />
                </p>
              </div>

              <div className="row mt-5">
                <div className="col">
                  <div className="accordion accordions" id="accordionExample2">
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id="headingaccmultiple1"
                      >
                        <h2 className="mb-0">
                          <button
                            className={accordionAbout.button}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#accmultiple1"
                            aria-expanded="true"
                            aria-controls="collapseaccmultiple1"
                            onClick={() => changeAccordionAbout()}
                          >
                            <FormattedMessage id="Navigation.about" />
                          </button>
                        </h2>
                      </div>
                      <div
                        id="accmultiple1"
                        className={accordionAbout.accordion}
                        aria-labelledby="headingaccmultiple1"
                        data-bs-parent="#accordionExample2"
                      >
                        <div className="accordion-body">
                          <About />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card border-0">
                        <div className="card-img-top ratio ratio-3x2">
                          <img src={cards[0].image} alt="..." />
                        </div>
                        <div className="card-body p-3">
                          <h5 className="card-title mt-4">
                            <Link
                              to={{
                                pathname: `/${lang}/search/Women's empowerment`,
                              }}
                              href="#!"
                              className="title-link stretched-link "
                            >
                              <FormattedMessage id={`Home.${cards[0].name}`} />
                            </Link>
                          </h5>
                          <p className="card-text mb-4"><FormattedMessage id={`Home.${cards[0].text}`} /></p>
                        </div>
                        {/* <div className="card-img-overlay d-flex flex-column justify-content-end">
                          <h5
                            className="card-title text-title"
                            data-pr-tooltip={cards[0].text}
                            data-pr-position="right"
                            data-pr-at="bottom-100 right+70"
                            data-pr-my="top center-2"
                            tooltipOptions={{ className: "tooltip-color" }}
                          >
                            <Link
                              to={{
                                pathname: `/${lang}/search/Women's empowerment`,
                              }}
                              className="title-link stretched-link text-color-primary-light"
                            >
                              <FormattedMessage id={`Home.${cards[0].name}`} />
                            </Link>
                          </h5>
                        </div> */}
                      </div>
                    </div>
                    <div className="col">
                      <div className="card border-0">
                        <div className="card-img-top ratio ratio-3x2">
                          <img src={cards[1].image} alt="..." />
                        </div>
                        <div className="card-body p-3">
                          <h5 className="card-title mt-4">
                            <Link
                              to={{
                                pathname: `/${lang}/search/Temporary special measures`,
                              }}
                              href="#!"
                              className="title-link stretched-link "
                            >
                              <FormattedMessage id={`Home.${cards[1].name}`} />
                            </Link>
                          </h5>
                          <p className="card-text mb-4"><FormattedMessage id={`Home.${cards[1].text}`} /></p>
                        </div>
                      </div>
                      {/* <div className="card card-overlay">
                        <div className="card-image ratio ratio-3x2">
                          <img src={cards[1].image} alt="..." />
                        </div>
                        <div className="card-img-overlay d-flex flex-column justify-content-end">
                          <h5
                            className="card-title text-title"
                            data-pr-tooltip={cards[1].text}
                            data-pr-position="right"
                            data-pr-at="bottom-100 right+70"
                            data-pr-my="top center-2"
                            tooltipOptions={{ className: "tooltip-color" }}
                          >
                            <Link
                              to={{
                                pathname: `/${lang}/search/Temporary special measures`,
                              }}
                              href="#!"
                              className="title-link stretched-link text-color-primary-light"
                            >
                              <FormattedMessage id={`Home.${cards[1].name}`} />
                            </Link>
                          </h5>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card border-0">
                        <div className="card-img-top ratio ratio-3x2">
                          <img src={cards[2].image} alt="..." />
                        </div>
                        <div className="card-body little p-3">
                          <h5 className="card-title little text-center">
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and natural resources`,
                              }}
                              href="#!"
                              className="title-link stretched-link "
                            >
                              <FormattedMessage id={`Home.${cards[2].name}`} />
                            </Link>
                          </h5>
                        </div>
                        {/* <div className="card-img-overlay d-flex flex-column justify-content-end">
                          <h5
                            className="card-title text-title-small"
                            data-pr-tooltip={cards[2].text}
                            data-pr-position="right"
                            data-pr-at="bottom+20 right+70"
                            data-pr-my="top center-2"
                            tooltipOptions={{ className: "tooltip-color" }}
                          >
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and natural resources`,
                              }}
                              href="#!"
                              className="title-link stretched-link text-color-primary-light"
                            >
                              <FormattedMessage id={`Home.${cards[2].name}`} />
                            </Link>
                          </h5>
                          <Tooltip
                            target=".card-title"
                            style={{ width: "250px" }}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col">
                      <div className="card border-0">
                        <div className="card-img-top ratio ratio-3x2">
                          <img src={cards[3].image} alt="..." />
                        </div>
                        <div className="card-body little p-3">
                          <h5 className="card-title little text-center">
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and nutrition`,
                              }}
                              href="#!"
                              className="title-link stretched-link "
                            >
                              <FormattedMessage id={`Home.${cards[3].name}`} />
                            </Link>
                          </h5>
                        </div>
                      </div>
                      {/* <div className="card card-overlay">
                        <div className="card-image ratio ratio-3x2">
                          <img src={cards[3].image} alt="..." />
                        </div>
                        <div className="card-img-overlay d-flex flex-column justify-content-end">
                          <h5
                            className="card-title text-title-small"
                            data-pr-tooltip={cards[3].text}
                            data-pr-position="right"
                            data-pr-at="bottom+20 right+70"
                            data-pr-my="top center-2"
                          >
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and nutrition`,
                              }}
                              href="#!"
                              className="title-link stretched-link text-color-primary-light"
                            >
                              <FormattedMessage id={`Home.${cards[3].name}`} />
                            </Link>
                          </h5>
                          <Tooltip
                            target=".card-title"
                            style={{ width: "250px" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="col">
                      <div className="card border-0">
                        <div className="card-img-top ratio ratio-3x2">
                          <img src={cards[4].image} alt="..." />
                        </div>
                        <div className="card-body little p-3">
                          <h5 className="card-title little text-center">
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and social protection`,
                              }}
                              href="#!"
                              className="title-link stretched-link "
                            >
                              <FormattedMessage id={`Home.${cards[4].name}`} />
                            </Link>
                          </h5>
                        </div>
                      </div>
                      {/* <div className="card card-overlay">
                        <div className="card-image ratio ratio-3x2">
                          <img src={cards[4].image} alt="..." />
                        </div>
                        <div className="card-img-overlay d-flex flex-column justify-content-end">
                          <h5
                            className="card-title text-title-small"
                            data-pr-tooltip={cards[4].text}
                            data-pr-position="right"
                            data-pr-at="bottom+20 right+70"
                            data-pr-my="top center-2"
                          >
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and social protection`,
                              }}
                              href="#!"
                              className="title-link stretched-link text-color-primary-light"
                            >
                              <FormattedMessage id={`Home.${cards[4].name}`} />
                            </Link>
                          </h5>
                          <Tooltip
                            target=".card-title"
                            style={{ width: "250px" }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="col">
                      <div className="card border-0">
                        <div className="card-img-top ratio ratio-3x2">
                          <img src={cards[5].image} alt="..." />
                        </div>
                        <div className="card-body little p-3">
                          <h5 className="card-title little text-center">
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and financial services`,
                              }}
                              href="#!"
                              className="title-link stretched-link"
                            >
                              <FormattedMessage id={`Home.${cards[5].name}`} />
                            </Link>
                          </h5>
                        </div>
                      </div>
                      {/* <div className="card card-overlay">
                        <div className="card-image ratio ratio-3x2">
                          <img src={cards[5].image} alt="..." />
                        </div>
                        <div className="card-img-overlay d-flex flex-column justify-content-end">
                          <h5
                            className="card-title text-title-small"
                            data-pr-tooltip={cards[5].text}
                            data-pr-position="right"
                            data-pr-at="bottom+20 right+70"
                            data-pr-my="top center-2"
                          >
                            <Link
                              to={{
                                pathname: `/${lang}/search/Gender and financial services`,
                              }}
                              href="#!"
                              className="title-link stretched-link text-color-primary-light"
                            >
                              <FormattedMessage id={`Home.${cards[5].name}`} />
                            </Link>
                          </h5>
                          <Tooltip
                            target=".card-title"
                            style={{ width: "250px" }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row mx-5">
                <div className="col">
                  <h5 className="sub-title">
                    <FormattedMessage id="Navigation.related" />
                  </h5>
                  {relatedLinks
                    .filter((r) => r.category === 1)
                    .map((link) => (
                      <div key={link.name_en}>
                        <ul className="simple-list list-unstyled">
                          <a
                            href={
                            link.url_fr!=undefined && lang=='fr'?link.url_fr:
                            link.url_ru!=undefined && lang=='ru'?link.url_ru:
                            link.url_es!=undefined && lang=='es'?link.url_es:
                            link.url_zh!=undefined && lang=='zh'?link.url_zh:
                            link.url_ar!=undefined && lang=='ar'?link.url_ar:
                            link.url_en}
                            className="link-icon"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bi bi-globe"></i>
                            <span>{
                            link.name_fr!=undefined && lang=='fr'?link.name_fr:
                            link.name_zh!=undefined && lang=='zh'?link.name_zh:
                            link.name_es!=undefined && lang=='es'?link.name_es:
                            link.name_ru!=undefined && lang=='ru'?link.name_ru:
                            link.name_ar!=undefined && lang=='ar'?link.name_ar:
                            link.name_en}</span>
                          </a>
                        </ul>
                      </div>
                    ))}
                  <h5 className="sub-title">
                    <FormattedMessage id="Navigation.fao-resources" />
                  </h5>
                  {relatedLinks
                    .filter((r) => r.category === 3)
                    .map((link) => (
                      <div key={link.name_en}>
                        <ul className="simple-list list-unstyled">
                          <a
                            href={
                            link.url_fr!=undefined && lang=='fr'?link.url_fr:
                            link.url_ru!=undefined && lang=='ru'?link.url_ru:
                            link.url_es!=undefined && lang=='es'?link.url_es:
                            link.url_zh!=undefined && lang=='zh'?link.url_zh:
                            link.url_ar!=undefined && lang=='ar'?link.url_ar:
                            link.url_en}
                            className="link-icon"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="bi bi-file-earmark-text"></i>
                            <span>{
                            link.name_fr!=undefined && lang=='fr'?link.name_fr:
                            link.name_zh!=undefined && lang=='zh'?link.name_zh:
                            link.name_es!=undefined && lang=='es'?link.name_es:
                            link.name_ru!=undefined && lang=='ru'?link.name_ru:
                            link.name_ar!=undefined && lang=='ar'?link.name_ar:
                            link.name_en
                            }</span>
                          </a>
                        </ul>
                      </div>
                    ))}
                </div>

                <div className="contact">
                  <p><FormattedMessage id="Home.contact" /></p>
                  <a
                    href="mailto:FAOLEX@fao.org"
                    title="Opens window for sending email"
                    className="mail"
                  >
                    FAOLEX@fao.org
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading">
          <div className="wrap-spinner">
            {" "}
            <ViewSpinner />{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
