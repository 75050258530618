import { useEffect, useState } from "react";
import Config from "../../config";
import { FormattedMessage } from "react-intl";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./CountryProfiles.css";
import ViewSpinner from "../../shared/Spinner";

function CountryProfiles({ lang }) {
  const [genderLexCp, setGenderlexCp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  var location = useLocation()

  let url = window.location.href.toString().split("?")[0];
  // let lang = url.substring(url.length - 2);
  // if (
  //   lang !== "fr" &&
  //   lang !== "es" &&
  //   lang !== "ru" &&
  //   lang !== "ar" &&
  //   lang !== "zh"
  // ) {
  //   lang = "en";
  // }
  document.documentElement.setAttribute("lang", lang);

  let countryLang = "country_" + lang;

  var lastLetter = "";

  useEffect(() => {
    setIsLoading(false);
    fetch(Config.apis.countryprofiles)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, "<-- data")

        setGenderlexCp(data.profile.sort());
      })
      .then(() => setTimeout(() => setIsLoading(true), 1000));
  }, [countryLang, lang]);

  const sortedGenderLexCp = genderLexCp.sort((a, b) => {
      if (a[countryLang] < b[countryLang]) return -1;
      if (a[countryLang] > b[countryLang]) return 1;
      return 0;
    });

  return (
    <div>
      {isLoading ? (
        <div id="country-profiles-page">
          <h2 className="page-title title">
              <FormattedMessage id="Country_profiles.country.title" />
          </h2>
          <div className="profiles-country-box">
            {sortedGenderLexCp !== undefined && sortedGenderLexCp !== null
              ? sortedGenderLexCp.map((item) => {
                  return (item.organization_Code === undefined ||
                    item.organization_Code === null) &&
                    (item.territory === undefined ||
                      item.territory === null) ? (
                    <div key={item[countryLang]}>
                      {undefined !== item[countryLang] &&
                      lastLetter !== item[countryLang].charAt(0) ? (
                        <div className="letter-alphabet">
                          <h4
                            style={{ paddingLeft: "5px", paddingRight: "5px", marginBottom: "5px" }}
                          >
                            {(lastLetter = item[countryLang].charAt(0))}
                          </h4>
                        </div>
                      ) : null}
                      <div
                        className="country-link"
                        key={item.country_ISO3_Code}
                      >
                        <Link
                          to={`profiles/${item.country_ISO3_Code}`}
                        >
                          <span
                            className={`flag flag-${item.country_ISO3_Code.toLowerCase()} flag-small`}
                          ></span>
                          {item[countryLang]}
                        </Link>
                      </div>
                    </div>
                  ) : null;
                })
              : null}
          </div>
        </div>
      ) : (
        <div className="loading">
          <div className="wrap-spinner">
            {" "}
            <ViewSpinner />{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default CountryProfiles;
