import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

function TypeSel({ fields, typeSelected }) {
  const [typesLoad, setTypesLoad] = useState([]);
  const [types, setTypes] = useState([{ name: "", text: "" }]);
  const [typeSel, setTypeSel] = useState([]);

  useEffect(() => {
    var jsonMapType = [
      {
        name: "Policy",
        value: "P",
        selected: false,
      },
      {
        name: "Legislation",
        value: "L",
        selected: false,
      },
      {
        name: "Regulation",
        value: "R",
        selected: false,
      },
      {
        name: "Miscellaneous",
        value: "M",
        selected: false,
      },
    ];

    const checkIfSelected = (type) => {
      if (typeSelected.length === 0) return true;
      for (let el of typeSelected) {
        if (el === type) return true;
      }

      return false;
    };

    fields.forEach((field) => {
      if (field.name === "type") setTypesLoad(field.textValues.values);
    });

    let typess = [];
    for (let el of typesLoad) {
      for (let jl of jsonMapType) {
        if (jl.name === el) {
          for (let fl of fields) {
            if (fl.name === jl.value) {
              typess.push({
                name: el,
                text: fl.textValues.values,
                selected: checkIfSelected(el),
              });
            }
          }
        }
      }
    }

    let typeUnsel = [];
    for (let el of typess) {
      for (let jl of jsonMapType) {
        if (jl.name === el.name) {
          if (!el.selected) {
            typeUnsel.push(el);
          }
        }
      }
    }

    setTypes(typess);
    setTypeSel(typeUnsel);
  }, [fields, typeSelected, typesLoad]);

  return (
    <div className="topics">
      {types.map((type) =>
        type.selected ? (
          <div className="topic-wrap" key={type.name}>
            <div className="topic-name">
              <h4>{type.name}:</h4>
            </div>
            <div className="topic-text">
              <p>{type.text}</p>
            </div>
          </div>
        ) : null
      )}{" "}
      {typeSelected ? (
        typeSelected.length !== 0 && typeSel.length !== 0 ? (
          <div className="topics">
            <Accordion defaultActiveKey="0">
              <Accordion.Item>
                <Accordion.Header>
                  <h5 className="about">Other GENDER-LEX types</h5>
                </Accordion.Header>
                <Accordion.Body className="topic-acc-body">
                  {types.map((type) =>
                    !type.selected ? (
                      <div className="topic-wrap" key={type.name}>
                        <div className="topic-name">
                          <h4>{type.name}</h4>
                        </div>
                        <div className="topic-text">
                          <p>{type.text}</p>
                        </div>
                      </div>
                    ) : null
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : null
      ) : null}
    </div>
  );
}

export default TypeSel;
