import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Config from "../config";

export const DownloadXLS = (excelData, filesPdf, fileName) => {

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let ws = XLSX.utils.json_to_sheet(excelData);

  function ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }
  function delete_row(ws, row_index) {
    var variable = XLSX.utils.decode_range(ws["!ref"]);
    for (var R = row_index; R < variable.e.r; ++R) {
      for (var C = variable.s.c; C <= variable.e.c; ++C) {
        ws[ec(R, C)] = ws[ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws["!ref"] = XLSX.utils.encode_range(variable.s, variable.e);
  }

  delete_row(ws, 0);

  for (let i = 1; i < excelData.length + 1; i++) {
    ws[
      XLSX.utils.encode_cell({
        c: 0,
        r: i,
      })
    ].l = {
      Target: `${Config.urls.link_document}${
        excelData[i - 1].id
      }`,
    };
  }

  for (let i = 1; i < excelData.length + 1; i++) {
    ws[
      XLSX.utils.encode_cell({
        c: 2,
        r: i,
      })
    ].l = { Target: `${Config.urls.link_pdf}${filesPdf[i - 1]}` };
  }

  ws["!cols"] = [
    { wch: 15 },
    { wch: 15 },
    { wch: 40 },
    { wch: 10 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
  ];

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
