import { useEffect, useState } from "react";
import {
  Link,
  useRouteMatch,
  useParams,
  useLocation,
  Outlet,
} from "react-router-dom";
import PDFicon from "../images/file-pdf-solid.svg";
import Config from "../config";

function TitleDateSel({ fields, pdf }) {
  const [faoId, setFaoId] = useState("");

  useEffect(() => {
    fields.forEach((field) => {
      if (field.name.includes("faolexId")) {
        setFaoId(field.textValues.values[0]);
      }
    });
  });

  return (
    <div id="title-date">
      <div className="title-date-wrap">
        <div className="pdf-icon">
          <a
            href={`${Config.urls.link_pdf}${pdf}`}
            rel="noopener noreferrer"
            target="_blank"
            className="embed"
            title="View PDF"
          >
            <img src={PDFicon} alt="" />
          </a>
        </div>
        <div className="section-title">
          {fields.map((field) => (
            <div id="results" key={field.name}>
              {field.textValues
                ? field.name === "titleOfText"
                  ? field.textValues.values.map((title) =>
                      title ? (
                        <h5 className="title-link" key={title}>
                          <a
                            href={
                              window.location.pathname === "/search"
                                ? `search/document/${faoId}`
                                : `document/${faoId}`
                            }
                          >
                            {title}
                          </a>
                        </h5>
                      ) : null
                    )
                  : null
                : null}
            </div>
          ))}
        </div>

        <div className="section-date">
          {fields.map((field) => (
            <div id="results" key={field.name}>
              {field.textValues
                ? field.name === "dateOfText"
                  ? field.textValues.values.map((country) => (
                      <div key={country}>
                        <small>{country}</small>
                      </div>
                    ))
                  : field.name === "dateOfOriginalText"
                  ? field.textValues.values.map((country) => (
                      <div key={country}>
                        <small>{country}</small>
                      </div>
                    )) : null
                : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TitleDateSel;
