import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/primereact.min.css";
import { IntlProvider } from "react-intl";
import translations from "./i18n/locales";
import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import FaoPageContainer from "./shared/FaoPageContainer";
import Home from "./pages/Home_page/Home";
import CountryProfiles from "./pages/CountryProfiles/CountryProfiles";
import AdvancedSearch from "./pages/AdvancedSearch/AdvancedSearch";
import Documents from "./pages/_Documents/Documents";
import AllDocumentsView from "./helper/AllDocumentsView";
import DocumentsDetail from "./pages/DocumentsDetail/DocumentsDetail";
import DocumentDetailSearch from "./pages/DocumentsDetailSearch/DocumentDetailSearch";

const App = ({ lang }) => {
  var url = window.location.pathname.replace(".html", "");
  var language;
  if(url.includes("/"))
    language = url.split("/")[1];
  else
    language = "en";
  if (
     language !== "fr" &&
     language !== "es" &&
     language !== "ru" &&
     language !== "ar" &&
     language !== "zh"
   ) {
     language = "en";
   }

  return (
<IntlProvider
     locale={language}
     key={language}
     defaultLocale="en"
     messages={translations[language]}
 >
     <Router>
         <Routes>
             <Route path="/" element={<Navigate to="/en" replace />} />
             <Route path="/:lang" element={<FaoPageContainer lang={language} />}>
                 <Route path="" element={<Home />} />
                 <Route path="/:lang/country">
                     <Route index={true} element={<CountryProfiles lang={language} />} />
                     <Route path="profiles/:countryId">
                         <Route index={true} element={<Documents lang={language} />} />
                         <Route path="see-more/:subsectionTitle/:country">
                             <Route index={true} element={<DocumentsDetail lang={language} />} />
                             <Route
                                 index={false}
                                 path="document/:faolexid"
                                 element={<DocumentDetailSearch />}
                             />
                         </Route>
                         <Route
                             index={false}
                             path="document/:faolexid"
                             element={<DocumentDetailSearch />}
                         />
                     </Route>
                 </Route>
                 <Route
                     path="/:lang/country-snapshot/:countryId"
                     element={<AllDocumentsView lang={language} />}
                 />
                 <Route
                     index={false}
                     path="/:lang/document/:faolexid"
                     element={<DocumentDetailSearch lang={language} />}
                 />
                 <Route path="/:lang/search">
                     <Route index={true} element={<AdvancedSearch lang={language} />} />
                     <Route
                         index={false}
                         path=":topicSel"
                         element={<AdvancedSearch lang={language} />}
                     />
                     <Route
                         index={false}
                         path="country/profiles/:countryId"
                         element={<Documents lang={language} />}
                     />
                 </Route>
             </Route>
             <Route path="*" element={<Navigate to="/en" replace />} />
         </Routes>
     </Router>
 </IntlProvider>
  );
};

export default App;
