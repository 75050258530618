import { Costants } from "./Costants";

export const QueryCountryDocumentsSearch = (countryid, page) => {
 
  let facetOptions = [
    { operatorName: "year", numFacetBuckets: 100 },
    { operatorName: "countries", numFacetBuckets: 100 },
    { operatorName: "mainareas", numFacetBuckets: 100 },
    { operatorName: "subjectselections", numFacetBuckets: 100 },
    { operatorName: "keywords", numFacetBuckets: 100 },
    { operatorName: "geographicalareas", numFacetBuckets: 100 },
    { operatorName: "typeoftexts", numFacetBuckets: 100 },
    { operatorName: "documentlanguages", numFacetBuckets: 100 },
    { operatorName: "territorialsubdivisions", numFacetBuckets: 100 },
    { operatorName: "organization", numFacetBuckets: 100 },
  ];

   let queryAdvSearch = {
      query: `* country:('${countryid}')`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6b09629075d87b057f",
      },
      facetOptions: facetOptions,
      start: page,
      pageSize: 100
    };
  
  return JSON.stringify(queryAdvSearch);
};
