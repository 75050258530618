export const QueryDetailDocument = (faolexid) => {

 /**
   searchapplications/1be285f8874b8c6bfaabf84aa9d0c1be LEX-FAOC214131
   */

   let queryDetailDocument = {
      query: `faolexid:(${faolexid})`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6bfaabf84aa9d0c1be"
      },
      facetOptions: [
        { operatorName: "year", numFacetBuckets: 100 },
        { operatorName: "countries", numFacetBuckets: 100 },
        { operatorName: "mainareas", numFacetBuckets: 100 },
        { operatorName: "subjectselections", numFacetBuckets: 100 },
        { operatorName: "keywords", numFacetBuckets: 100 },
        { operatorName: "geographicalareas", numFacetBuckets: 100 },
        { operatorName: "typeoftexts", numFacetBuckets: 100 },
        { operatorName: "documentlanguages", numFacetBuckets: 100 },
        { operatorName: "territorialsubdivisions", numFacetBuckets: 100 },
        { operatorName: "organization", numFacetBuckets: 100 },
      ],
      start: 0
    };
  
  return JSON.stringify(queryDetailDocument);
};
